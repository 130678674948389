import SpinnerContainer from 'common/components/SpinnerContainer';
import { MonitoredAmm, MONITORED_AMMS } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import MonitorVaultsTable from 'common/components/MonitorVaultsTable/MonitorVaultsTable';
import { ContentTableWrapper } from './app.style';
import MonitorVaultsTableFilters from 'common/components/MonitorVaultsTable/MonitorVaultsTableFilters';
import { StyledFlexRow } from 'common/styles/common.styles';
import { ChainId } from '@ichidao/ichi-sdk/dist/src/crypto/networks';
import { usePaginatedFarms } from 'common/hooks/usePaginatedFarms';
import { usePaginatedStrategies } from 'common/hooks/usePaginatedStrategies';
import { usePaginatedMonitorVaults } from 'common/hooks/usePaginatedMonitorVaults';

// Status indicator component to show when auto-refresh occurs
const RefreshStatus = ({ isRefreshing }: { isRefreshing: boolean }) => {
  if (!isRefreshing) return null;
  
  return (
    <div className="refresh-status" style={{ 
      position: 'fixed', 
      bottom: '10px', 
      right: '10px', 
      padding: '8px 12px',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: 'white',
      borderRadius: '4px',
      fontSize: '12px',
      zIndex: 1000
    }}>
      Refreshing data...
    </div>
  );
};

type Props = {
  onlyNeedRebalance: boolean;
};
export enum LiquidityAmountInVaults {
  NoLiquidity = 0,
  OnlyWithLiquidity = 1,
  Both = 2,
}

const MonitorVaultsContainer: React.FC<Props> = (props) => {
  const [tab, setTab] = useState<MonitoredAmm>(MONITORED_AMMS[0].amms[0]);
  const [criterion, setCriterion] = useState<string>('tvl');
  const [liquidityFilter, setLiquidityFilter] = 
    useState<LiquidityAmountInVaults>(LiquidityAmountInVaults.OnlyWithLiquidity);

  const { 
    farms, 
    loading: loadingFarms, 
    error: errorFarms,
    refreshAll: refreshFarms,
    isRefreshing: isRefreshingFarms
  } = usePaginatedFarms();
  
  const {
    vaults,
    loading: loadingVaults,
    error: errorVaults,
    refreshAll: refreshVaults,
    isRefreshing: isRefreshingVaults
  } = usePaginatedMonitorVaults(props.onlyNeedRebalance);

  const {
    strategies,
    loading: loadingStrategies,
    error: errorStrategies,
    refreshAll: refreshStrategies,
    isRefreshing: isRefreshingStrategies
  } = usePaginatedStrategies();
  
  // Load all data on initial component mount
  useEffect(() => {
    const loadAllData = async () => {
      // Load all data in parallel
      await Promise.all([
        refreshFarms(),
        refreshVaults(),
        refreshStrategies()
      ]);
    };
    
    loadAllData();
  }, []);
  
  // Handle tab change - no need to refresh data as we already have all data
  // But we might want to add a filter if needed
  
  // Is any data currently being refreshed?
  const isAnyRefreshing = isRefreshingFarms || isRefreshingVaults || isRefreshingStrategies;
    
  // Manual refresh handler - refreshes all data sources
  const handleManualRefresh = () => {
    refreshFarms();
    refreshVaults();
    refreshStrategies();
  };

  const chainId = tab.chain;

  const table = {
    'headers': [
      {
        'name': 'Name',
        'sortBy': 'displayname'
      },
      {
        'name': 'DTR',
        'helpText': '',
        'sortBy': 'deposittokenratio'
      },
      {
        'name': 'Price',
        'helpText': ''
      },
      {
        'name': '*',
        'helpText': ''
      },
      {
        'name': 'Base Range',
        'helpText': ''
      },
      {
        'name': 'Limit Range',
        'helpText': ''
      },
      {
        'name': 'Pend. Dep.',
        'helpText': '',
        'sortBy': 'pendingdepositratio'
      },
      {
        'name': 'Last Rebal.',
        'helpText': '',
        'sortBy': 'lastrebalance'
      },
      {
        'name': chainId === ChainId.Celo ? 'Comb. Ratio' : 'LP price',
        'helpText': '',
      },
    ]
  };

  // Combine all errors to show
  const anyError = errorFarms || errorVaults || errorStrategies;
  if (anyError) {
    console.error(anyError);
  }

  const loading = loadingFarms || loadingStrategies || loadingVaults;

  return (
    <SpinnerContainer isLoading={false}>
      <MonitorVaultsTableFilters 
        handleClick={(amm: MonitoredAmm) => setTab(amm)} 
        currTab={tab} 
        handleSorting={(sortBy: string) => setCriterion(sortBy)}
        handleLiquidityAmount={(liqAmnt: LiquidityAmountInVaults) => setLiquidityFilter(liqAmnt)}
        currLiquidityAmountFilter={liquidityFilter}
      />
      <ContentTableWrapper>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}>
          <button
            onClick={handleManualRefresh}
            disabled={loading || isAnyRefreshing}
            className="primary-button-bg-color m-05 p-1"
          >
            {loading || isAnyRefreshing ? 'Refreshing...' : 'Refresh Data'}
          </button>
        </div>
        
        <MonitorVaultsTable 
          table={table} 
          vaults={vaults} 
          farms={farms} 
          strategies={strategies}
          filter={tab} 
          liquidityFilter={liquidityFilter}
          handleSorting={(sortBy: string) => setCriterion(sortBy)}
          criterion={criterion} 
        />
      </ContentTableWrapper>
      
      <StyledFlexRow gap='12px'>
        <span>H - healthy</span>
        <span>D - defensive</span>
        <span>U - under-inventory</span>
        <span>O - over-inventory</span>
      </StyledFlexRow>
      <StyledFlexRow gap='12px'>
        <span>H(with red background) - tick is outside the concentrated position</span>
      </StyledFlexRow>
      
      {/* Status indicator for auto-refresh */}
      <RefreshStatus isRefreshing={isAnyRefreshing} />
    </SpinnerContainer>
  );
};

export default MonitorVaultsContainer;